.header {
  height: 64px;
  &__text {
    &__amaStudios {
      font-family: "Bebas Neue" !important;
      font-size: 35px !important;
      color: #ffffff;
      &__x {
        margin-left: 30px;
        margin-right: 30px;
      }
      &__sandbox-logo {
        margin-bottom: -3px;
        height: 30px;
      }
    }
  }
  &__button {
    width: 150px;
    height: 50px;
    font-family: "Bebas Neue" !important;
    font-size: 26px !important;
    color: #ffffff !important;
    border: 3px solid rgba(255, 140, 34, 1) !important;
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    height: 64px;
    &__text {
      &__amaStudios {
        font-family: "Bebas Neue" !important;
        font-size: 23px !important;
        color: #ffffff;
        &__x {
          margin-left: 10px;
          margin-right: 10px;
        }
        &__sandbox-logo {
          margin-bottom: -3px;
          height: 22px;
        }
      }
    }
    &__button {
      width: 150px;
      height: 50px;
      font-family: "Bebas Neue" !important;
      font-size: 23px !important;
      color: #ffffff !important;
      border-color: rgba(255, 140, 34, 1) !important;
    }
  }
}
