.home {
  height: 100vh;
  width: 100vw;
  background-color: black;
  &__mobile {
    &__header {
      display: none;
    }
  }
  &__videoContainer {
    position: relative;
    height: 100vh;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 100vh;
      content: "";
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 99%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    &__video {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }
  &__wrapper {
    max-width: 700px !important;
    &__header {
      // position: absolute;
      // top: 2%;
      // width: 50%;
      margin-bottom: 70px;
    }
    &__content {
      margin: auto;
      &__text1 {
        font-family: "Bebas Neue" !important;
        font-size: 24px !important;
        color: rgba(255, 140, 34, 0.5);
        letter-spacing: 7px !important;
      }
      &__text2 {
        font-family: "Bebas Neue" !important;
        font-size: 120px !important;
        color: #ffffff;
        line-height: 150px !important;
      }
      &__text3 {
        max-width: 820px;
        font-family: "Causten" !important;
        font-size: 21px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 25px !important;
      }
      &__buttons {
        margin-top: 20px !important;
        &__raffle {
          width: 250px;
          height: 50px;
          font-family: "Bebas Neue" !important;
          font-size: 24px !important;
          background-color: rgba(255, 140, 34, 1) !important;
        }
        &__whitepaper {
          width: 250px;
          height: 50px;
          font-family: "Bebas Neue" !important;
          font-size: 24px !important;
          color: #ffffff !important;
          border: 3px solid rgba(255, 140, 34, 1) !important;
        }
      }
    }
  }
}

// Style for landscape mobile screen or tablet
@media only screen and (max-width: 1200px) {
  .home {
    height: auto;
    width: 100%;
    &__mobile {
      &__header {
        display: block;
        position: absolute;
        z-index: 1;
        width: calc(100% - 60px);
        padding: 30px;
      }
    }
    &__videoContainer {
      position: relative;
      height: 800px;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 800px;
        content: "";
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 90%,
          rgba(0, 0, 0, 1) 100%
        );
      }
      &__video {
        height: 800px;
        width: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }
    &__wrapper {
      max-width: auto !important;
      margin-top: -400px !important;
      &__header {
        display: none;
      }
      &__content {
        margin: auto;
        position: relative;
        top: 0%;
        left: 0%;
        transform: none;
        max-width: 100% !important;
        padding: 30px !important;
        &__text1 {
          font-size: 19px !important;
        }
        &__text2 {
          font-size: 60px !important;
          line-height: 80px !important;
        }
        &__text3 {
          font-size: 21px !important;
          line-height: 26px !important;
        }
        &__buttons {
          &__raffle {
            width: 100%;
            height: 64px;
            font-size: 29px !important;
          }
          &__whitepaper {
            width: 100%;
            height: 64px;
            font-size: 29px !important;
          }
        }
      }
    }
  }
}
